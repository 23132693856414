import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, InputLabel, Select as MUISelect, } from '@mui/material';
export function Select(props) {
    const id = props?.id || props.label.toLowerCase().split(' ').join('-');
    const { helperText, ...selectProps } = props;
    return (_jsxs(FormControl, { children: [_jsx(InputLabel, { id: `${id}-label`, children: props.label }), _jsx(MUISelect, { id: id, labelId: `${id}-label`, ...selectProps, SelectDisplayProps: {
                    ...props.SelectDisplayProps,
                    'aria-invalid': props.error,
                } }), Boolean(helperText) && _jsx(FormHelperText, { error: props.error, children: helperText })] }));
}
