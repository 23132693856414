/**
 * Throw this error when response from the server is not ok. This will be accessible in
 * react-query's handlers with access to `error` param and as the `error` variable returned by the
 * hook itself.
 */
export class ReactQueryError extends Error {
  constructor(message: string, public readonly response: Response) {
    super(message);
  }
}
