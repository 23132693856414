import { jsx as _jsx } from "react/jsx-runtime";
import { Button, styled, Typography } from '@mui/material';
import { useState, } from 'react';
import { TextField } from './text-field';
const VRT_PADDING = 1;
const HRZ_PADDING = 1.5;
const StyledButton = styled(Button)(({ theme }) => ({
    padding: `${theme.spacing(VRT_PADDING)} ${theme.spacing(HRZ_PADDING)}`,
    ':hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));
export function EditableTitle(props) {
    const { size, value, setValue } = props;
    const [editingTitle, setEditingTitle] = useState(false);
    const [titleDraft, setTitleDraft] = useState(value);
    const typographyVariant = size === 'small' ? 'body1' : 'h6';
    function handleSave(event) {
        if (event.key === 'Enter') {
            setValue(titleDraft);
            setEditingTitle(false);
        }
    }
    function handleChange(event) {
        setTitleDraft(event.target.value);
    }
    function handleNoSave() {
        setTitleDraft(value);
        setEditingTitle(false);
    }
    return editingTitle ? (_jsx(TextField, { color: 'white', variant: 'minimal', defaultValue: titleDraft, onChange: handleChange, onKeyDown: handleSave, onBlur: handleNoSave, autoFocus: true, typography: typographyVariant, multiline: true })) : (_jsx(StyledButton, { size: 'small', variant: 'text', disableRipple: true, onClick: () => setEditingTitle(true), children: _jsx(Typography, { variant: typographyVariant, align: 'left', children: value }) }));
}
