import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AccordionDetails as MUIAccordionDetails, styled, useTheme, } from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DashedBorder } from './style';
const DOTTED_LINE_OFFSET_ADJUST = -4;
const DOTTED_LINE_HEIGHT_ADJUST = -3;
const DOTTED_LINE_WIDTH_ADJUST = -1;
const CONTENT_MARGIN_LEFT = 4;
const CONTENT_MARGIN_TOP = -2;
const StyledAccordionDetails = styled(MUIAccordionDetails)(({ theme }) => {
    return {
        padding: theme.spacing(0),
        marginBottom: theme.spacing(1),
        '& > .content': {
            position: 'relative',
            top: theme.spacing(CONTENT_MARGIN_TOP),
            marginLeft: theme.spacing(CONTENT_MARGIN_LEFT),
        },
    };
});
export default function AccordionDetails(props) {
    const children = props.children;
    const ref = useRef(null);
    const theme = useTheme();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [offset, setOffset] = useState(0);
    function spacingPixels(value) {
        return parseInt(theme.spacing(value), 10);
    }
    function handleResize() {
        const accordionSummary = ref.current
            ?.closest('.MuiAccordion-root.Mui-expanded')
            ?.getElementsByClassName('MuiAccordionSummary-root')
            .item(0);
        if (ref.current && accordionSummary) {
            setWidth(ref.current.clientWidth + spacingPixels(DOTTED_LINE_WIDTH_ADJUST));
            setHeight(ref.current.clientHeight + spacingPixels(DOTTED_LINE_HEIGHT_ADJUST));
            setOffset(accordionSummary.clientHeight + spacingPixels(DOTTED_LINE_OFFSET_ADJUST));
        }
    }
    useLayoutEffect(() => {
        handleResize();
    }, []);
    useEffect(() => {
        const parentAccordion = ref.current?.closest('.MuiAccordion-root');
        if (!parentAccordion) {
            return;
        }
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(parentAccordion);
        return () => resizeObserver.disconnect();
    }, []);
    return (_jsxs(StyledAccordionDetails, { ...props, ref: ref, children: [_jsx(DashedBorder, { width: width, height: height, offset: offset }), _jsx("div", { className: 'content', children: children })] }));
}
