import { jsx as _jsx } from "react/jsx-runtime";
import { Divider, Grid, styled } from '@mui/material';
import { Children } from 'react';
const StyledDivider = styled(Divider)(({ theme }) => {
    const lineAdjust = 1.5;
    return {
        marginTop: theme.spacing(1 + lineAdjust),
        marginBottom: theme.spacing(lineAdjust),
        marginLeft: theme.spacing(1),
    };
});
export function InputGroup({ children, className }) {
    const arrChildren = Children.toArray(children);
    const formattedChildren = [];
    Children.forEach(arrChildren, (child, idx) => {
        const isLast = idx === arrChildren.length - 1;
        formattedChildren.push(_jsx(Grid, { item: true, children: child }, idx));
        if (!isLast) {
            formattedChildren.push(_jsx(StyledDivider, { orientation: 'vertical', flexItem: true, variant: 'middle' }, `${idx}_divider`));
        }
    });
    return (_jsx(Grid, { direction: 'row', container: true, item: true, spacing: 1, alignItems: 'center', className: className, children: formattedChildren }));
}
