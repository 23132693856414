/**
 * Import this as early as possible in the app's lifecycle, per Sentry's recommendation.
 *
 * Usage:
 * import '/sentry.ts';
 */

import * as Sentry from '@sentry/react';
import type { ConfigData } from 'rachael-server';

import { internalConfig } from './config';

function isSLOTransaction(name: string): boolean {
  // home/analytics page
  if (name === '/') {
    return true;
  }

  // conversation list or detail pages
  if (name.startsWith('/conversations')) {
    return true;
  }

  return false;
}

const { dsn, isDebug, isEnabled } = internalConfig.sentry;

if (isEnabled) {
  try {
    Sentry.init({
      beforeSend(event) {
        // filter out errors triggered by this scanner
        if (
          event.request?.headers?.['User-Agent'].includes(
            'vuln_scan_by_trustedsite_com_halo_security'
          )
        ) {
          return null;
        }

        // exclude "TrustedSite" scans
        if (event.contexts?.trace?.data?.['http.client_ip'] === '74.82.62.132') {
          return null;
        }

        return event;
      },
      debug: isDebug,
      dsn,
      enabled: isEnabled,
      environment: internalConfig.env,
      ignoreErrors: [
        // noise from call playback library
        'Error: AbortError: The play() request was interrupted by a call to pause().',
        // spammy outlook safelinks error
        'Object Not Found Matching Id:',
      ],
      integrations: [Sentry.browserTracingIntegration()],
      release: process.env.PUBLIC_SENTRY_RELEASE,
      tracesSampler({ name }) {
        if (isSLOTransaction(name)) {
          return 1;
        }

        // sample 10% of other transactions
        return 0.1;
      },
    });
  } catch (err) {
    console.warn('Failed to initialise Sentry', err);
  }
} else {
  console.debug('Sentry is disabled!');
}

/**
 * Handle integration with the way we get config. If an error occurs before this gets called, these values won't be searchable in their UI.
 */
export function onConfigLoaded(configData: ConfigData): void {
  if (!isEnabled) {
    return;
  }

  Sentry.setTag('tenantSlug', configData.tenantSlug);
  Sentry.setTag('tenantUuid', configData.tenantUuid);
  Sentry.setUser({ email: configData.user?.email });
}

export default Sentry;
