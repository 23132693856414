import type { ConfigData, User } from 'rachael-server';
import { createContext, useContext } from 'react';

/**
 * ConfigData is the data type returned by the /data/config API endpoint. It has an optional "user"
 * attribute. Config is the same thing, but with the user no longer optional. We make this
 * distinction because the vast majority of the application code assumes the existence of a logged
 * in user, but we still need to be able to handle the case where a user is *not* logged in. The
 * former can use the useConfig hook (exported as default), while the latter can use the special
 * useConfigData hook.
 */
export type Config = ConfigData & { user: User };

export const ConfigContext = createContext<ConfigData | null>(null);

export function useConfigData(): ConfigData {
  const configData = useContext(ConfigContext);

  if (!configData) {
    throw new Error('useConfigData called outside a ConfigData provider');
  }

  return configData;
}

export default function useConfig(): Config {
  const configData = useConfigData();

  if (!configData.user) {
    throw new Error('No logged in user');
  }

  return configData as Config;
}

export class SuperUserConfig {
  static setIsViewingAsCustomer(isImpersonating: boolean) {
    // If you update this dont forget to also update index.html
    localStorage.setItem('superuser.viewAsCustomer', `${isImpersonating}`);

    // This isnt exactly reactive but in this case a full refresh makes sense and because
    // we pass this through to the server in index.html via the config endpoint
    window?.location?.reload();
  }
}

type EnvNames = 'dev' | 'stage' | 'prod';

export function getEnvFromUrl(): EnvNames {
  const { hostname } = window.location;

  if (hostname.endsWith('.dev.replicant.ai') || hostname.endsWith('.local.replicant.ai')) {
    return 'dev';
  } else if (hostname.endsWith('.stage.replicant.ai')) {
    return 'stage';
  } else if (hostname.endsWith('.replicant.ai')) {
    return 'prod';
  }

  // catchall for local, tests, etc
  return 'dev';
}

const env = getEnvFromUrl();

export const internalConfig = {
  env,
  sentry: {
    dsn: 'https://94a59cc11da6f6389339188cbf97c058@o4506914998648832.ingest.us.sentry.io/4506915058024448',
    isEnabled: env === 'prod' || env === 'stage',
    isDebug: env === 'dev',
  },
  statsig: {
    isAnalyticsEnabled: env === 'prod' || env === 'stage',
    key: 'client-TaFOve4ZKFUnG2qPXCmBmLCFciwVNLYL4228FkBWbaH',
  },
};
