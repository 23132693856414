import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
export function DashedBorder({ width, height, offset }) {
    const padding = 8;
    const theme = useTheme();
    return (_jsx("svg", { viewBox: '0 0 1 1', preserveAspectRatio: 'none', style: {
            position: 'relative',
            top: -offset,
            left: padding,
            overflow: 'visible',
            height: '1px',
            width: '1px',
        }, children: _jsx("path", { d: `M0 0 V${height + offset} H${width}`, fill: 'none', stroke: theme.palette.grey[400], strokeDasharray: '10', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }) }));
}
