import { jsx as _jsx } from "react/jsx-runtime";
import { ArrowForwardIosSharp } from '@mui/icons-material';
import { AccordionSummary as MUIAccordionSummary, styled, } from '@mui/material';
const ICON_TOP_MARGIN = 2.8;
const ExpandIcon = styled(ArrowForwardIosSharp)(({ theme }) => ({
    fontSize: theme.spacing(2),
}));
const StyledAccordionSummary = styled(MUIAccordionSummary)(({ theme }) => ({
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        alignSelf: 'flex-start',
        marginTop: theme.spacing(ICON_TOP_MARGIN),
    },
    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
        marginLeft: theme.spacing(2),
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'transparent',
    },
}));
export default function AccordionSummary(props) {
    return _jsx(StyledAccordionSummary, { ...props, expandIcon: _jsx(ExpandIcon, {}) });
}
