import { jsx as _jsx } from "react/jsx-runtime";
import { styled, TextField as MuiTextField, } from '@mui/material';
export const MinimalVariantName = 'minimal';
const MinimalStyledTextField = styled(MuiTextField, {
    shouldForwardProp: (prop) => prop !== 'typography',
})(({ typography, color, theme }) => ({
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& > .MuiInputBase-root': {
        backgroundColor: theme.palette.action.selected,
        color: color === 'white' ? theme.palette.common.white : undefined,
    },
    '& .MuiInputBase-root, & .MuiInput-root': theme.typography[typography || 'body1'],
}));
export function MinimalTextField(props) {
    const newProps = { ...props, variant: 'outlined' };
    return _jsx(MinimalStyledTextField, { ...newProps });
}
