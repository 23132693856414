/* eslint-disable @typescript-eslint/no-empty-interface */

import { blue, blueGrey, common, deepPurple, orange, pink, red, teal } from '@mui/material/colors';
import { alpha, colors, createTheme, type PaletteColor } from '@replicant/design-system';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
  }

  interface Palette {
    data: PaletteColor[];
    other: {
      divider: string;
      outlinedBorder: {
        23: string;
      };
    };
    waveform: {
      wave: string;
      progress: string;
      cursor: string;
    };
    blockCategories: {
      advanced: PaletteColor;
      condition: PaletteColor;
      inform: PaletteColor;
      collect: PaletteColor;
      flow: PaletteColor;
      flowEntry: PaletteColor;
      flowOutcome: PaletteColor;
      boolean: PaletteColor;
    };
  }

  interface PaletteOptions extends Partial<Palette> {}

  interface Mixins {
    waveform: {
      height: number;
      margin: number;
    };
  }

  interface MixinsOptions extends Partial<Mixins> {}
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    white: true;
    yellow: true;
  }

  interface SvgIconPropsSizeOverrides {
    'x-small': true;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    fontWeightSemiBold: CSSProperties['fontWeight'];
  }

  interface FontStyleOptions extends Partial<FontStyle> {}
}

const defaultTheme = createTheme({});

const root = document.getElementById('root') as HTMLElement;

export const theme = createTheme(defaultTheme, {
  mixins: {
    waveform: {
      height: parseInt(defaultTheme.spacing(15), 10),
      margin: parseInt(defaultTheme.spacing(4), 10),
    },
  },
  palette: {
    other: {
      divider: alpha(defaultTheme.palette.common.black, 0.12),
      outlinedBorder: {
        23: alpha(defaultTheme.palette.common.black, 0.23),
      },
    },
    data: [
      { main: '#165BAA', light: '#63ABFD' },
      { main: '#A155B9', light: '#E697FF' },
      { main: '#F765A3', light: '#FFA5CB' },
      { main: '#16BFD6', light: '#82F0FF' },
      { main: '#1DDD8D', light: '#7AFFC7' },
    ],
    waveform: {
      wave: colors.blueGrey[400],
      progress: colors.blueGrey[600],
      cursor: defaultTheme.palette.primary.main,
    },
    blockCategories: {
      advanced: { main: orange[600], contrastText: common.white },
      condition: { main: blueGrey[400], contrastText: common.white },
      inform: { main: pink[400], contrastText: '#FFFFFF' },
      collect: { main: deepPurple[400], contrastText: common.white },
      boolean: { main: teal[400], contrastText: common.white },
      flow: { main: blue[600], contrastText: common.white },
      flowEntry: { main: '#65BF73', contrastText: common.white },
      flowOutcome: { main: red[800], contrastText: common.white },
    },
  },
  typography: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontWeightSemiBold: 600,
    button: {
      textTransform: 'none',
    },
    h7: {
      ...defaultTheme.typography.h6,
      fontWeight: 500,
      fontSize: '1.125rem',
      letterSpacing: '0px',
    },
    h8: {
      ...defaultTheme.typography.h6,
      fontWeight: 400,
      fontSize: '1rem',
      letterSpacing: '0px',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h7: 'h6',
          h8: 'h6',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: root,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: root,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: root,
      },
    },
    MuiModal: {
      defaultProps: {
        container: root,
      },
    },
    MuiDataGrid: {
      defaultProps: {
        componentsProps: {
          baseFormControl: {
            fullWidth: false,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
        },
      },
    },
    MuiAppBar: {
      variants: [
        {
          props: { color: 'default' },
          style: {
            borderBottom: `1px solid ${defaultTheme.palette.divider}`,
            backgroundColor: defaultTheme.palette.common.white,
            zIndex: 100,
            '> .MuiTabs-root': {
              marginBottom: '-1px',
            },
          },
        },
      ],
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { color: 'yellow' },
          style: {
            color: colors.yellow[600],
          },
        },
        {
          props: { fontSize: 'x-small' },
          style: {
            fontSize: defaultTheme.spacing(1.75),
          },
        },
      ],
    },
    MuiFormControl: {
      variants: [
        {
          props: { color: 'white' },
          style: {
            '.MuiInputBase-root': {
              backgroundColor: 'transparent !important',
              color: defaultTheme.palette.common.white,
            },
            '.MuiSvgIcon-root': {
              color: defaultTheme.palette.common.white,
            },
            '.MuiFormLabel-colorWhite': {
              color: alpha(defaultTheme.palette.common.white, 0.8),
            },
          },
        },
      ],
    },
  },
});
