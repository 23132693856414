import { jsx as _jsx } from "react/jsx-runtime";
import { Accordion as MUIAccordion, Collapse as MUICollapse, styled, } from '@mui/material';
const StyledAccordion = styled(MUIAccordion)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: 'transparent',
    ' .MuiInputBase-root': {
        backgroundColor: theme.palette.background.default, // TODO: move to theme for all input bases after checking downstream impact?
    },
}));
export default function DashedLineAccordion(props) {
    return _jsx(StyledAccordion, { ...props, disableGutters: true, TransitionComponent: MUICollapse });
}
