import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Grid, Paper, styled, useTheme } from '@mui/material';
import { DeleteButton, DragButton } from './icon-button';
const Card = styled(Paper)(({ theme }) => ({
    padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
    background: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    borderWidth: '0.5px', // consider 1px for firefox
    borderColor: theme.palette.grey[400],
}));
export function GroupCard(props) {
    const { children, onDelete, draggable, dense = false, removable, dragHandleProps } = props;
    const theme = useTheme();
    return (_jsx(Card, { variant: 'outlined', children: _jsxs(Grid, { container: true, direction: 'row', children: [_jsx(Grid, { item: true, hidden: !draggable, alignSelf: 'center', paddingLeft: theme.spacing(1), children: _jsx("div", { ...dragHandleProps, children: _jsx(DragButton, {}) }) }), _jsx(Grid, { container: true, item: true, xs: true, direction: 'column', rowSpacing: theme.spacing(dense ? 1 : 2), children: children }), _jsx(Grid, { item: true, hidden: !removable, paddingLeft: theme.spacing(1), children: _jsx(DeleteButton, { onClick: onDelete }) })] }) }));
}
